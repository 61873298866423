<template>
    <div class="loaderProduct"></div>
</template>
<script setup lang="ts"></script>
<style scoped>
.loaderProduct {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #474747;
    width: 24px;
    height: 24px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 1s ease-in-out infinite;
}
/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
